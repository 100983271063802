import { PropsWithChildren, useEffect, useState } from "react";
import { z } from "zod";
import { LeaguePlayer } from "../models/LeaguePlayer";
import { LeagueTeam } from "../models/LeagueTeam";
import { Match } from "../models/Match";
import { Season } from "../models/Season";

export interface WithClassName extends PropsWithChildren {
  className?: string;
}

export function useBreakpoint(breakPoint: number = 1024) {
  const [isBreakpoint, setIsBreakpoint] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia(`(max-width: ${breakPoint}px)`);
    const handleResize = () => setIsBreakpoint(mediaQuery.matches);

    handleResize();
    mediaQuery.addEventListener("change", handleResize);

    return () => {
      mediaQuery.removeEventListener("change", handleResize);
    };
  }, [breakPoint]);

  return isBreakpoint;
}

export function zstring() {
  return z.string().min(1);
}

export function splitArray(arr: any[], index: number = 5): any[][] {
  const firstArray = arr.slice(0, index);
  const restArray = arr.slice(index);
  return [firstArray, restArray];
}

export function getMatchWithTeams(teams: LeagueTeam[], match: Match) {
  const homeTeam = teams.find((t) => t.id === match.homeTeamId);
  const awayTeam = teams.find((t) => t.id === match.awayTeamId);
  if (!homeTeam || !awayTeam) {
    throw new Error("Match has incorrect homeTeamId or incorrect awayTeamId");
  }
  return { ...match, homeTeam, awayTeam };
}

export function getSeasonString(season: Season) {
  return `${season.startYear}-${season.endYear}`;
}

export function filterPlayersAndCoaches(players: LeaguePlayer[]) {
  return players.reduce<{ coaches: LeaguePlayer[]; players: LeaguePlayer[] }>(
    ({ coaches, players }, player) => {
      if (player.seasonPlayer.role === "Coach") {
        return { coaches: [...coaches, player], players };
      } else {
        return { coaches, players: [...players, player] };
      }
    },
    { coaches: [], players: [] }
  );
}

export function useIsScrolled(threshold: number = 0) {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > threshold;
      setScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [threshold]);

  return { scrolled };
}

export type UseCreateFilterPathFunc = {
  addFilter: (value: string, clearOnChange?: string[]) => string;
  removeFilter: (clearOnChange?: string[]) => string;
};

type LineupType = Pick<
  Match,
  | "homePlayerOneId"
  | "homePlayerTwoId"
  | "homePlayerThreeId"
  | "guestPlayerOneId"
  | "guestPlayerTwoId"
  | "guestPlayerThreeId"
  | "homeSubstitutePlayerOneId"
  | "homeSubstitutePlayerTwoId"
  | "homeSubstitutePlayerThreeId"
  | "guestSubstitutePlayerOneId"
  | "guestSubstitutePlayerTwoId"
  | "guestSubstitutePlayerThreeId"
>;
export function getGameLinupNumber(
  match: LineupType,
  playerId?: string | null
) {
  if (match.homePlayerOneId === playerId) {
    return 1;
  }
  if (match.homePlayerTwoId === playerId) {
    return 2;
  }
  if (match.homePlayerThreeId === playerId) {
    return 3;
  }
  if (match.homeSubstitutePlayerOneId === playerId) {
    return 4;
  }
  if (match.homeSubstitutePlayerTwoId === playerId) {
    return 5;
  }
  if (match.homeSubstitutePlayerThreeId === playerId) {
    return 6;
  }
  if (match.guestPlayerOneId === playerId) {
    return 1;
  }
  if (match.guestPlayerTwoId === playerId) {
    return 2;
  }
  if (match.guestPlayerThreeId === playerId) {
    return 3;
  }
  if (match.guestSubstitutePlayerOneId === playerId) {
    return 4;
  }
  if (match.guestSubstitutePlayerTwoId === playerId) {
    return 5;
  }
  if (match.guestSubstitutePlayerThreeId === playerId) {
    return 6;
  }
  return 1;
}
